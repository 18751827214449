import {
  ChangeEventHandler,
  CSSProperties,
  HTMLInputTypeAttribute,
  useEffect,
  useRef,
} from "react";
import { Flex, FlexType } from "../flex/flex";
import "./style/style.css";

export interface InputProps extends FlexType {
  xs?: number;
  md?: number;
  lg?: number;
  value?: any;
  readOnly?: boolean;
  title?: string | null;
  type?: HTMLInputTypeAttribute | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = ({
  title,
  value,
  type,
  onChange,
  xs = "auto",
  md,
  lg,
  readOnly = false,
  style,
  className,
  children,
  delay,
  onClick,
  gap = "8",
  padding = "0",
  align = "stretch",
  justify = "stretch",
  direction = "column",
  flex = "auto auto auto",
  animation,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    const updateLegendStyles = (inputElement: HTMLInputElement) => {
      const parentElement = inputElement.parentElement;

      if (parentElement) {
        const legend = parentElement.querySelector(
          ".custom-input-legend"
        ) as HTMLElement | null;
        if (legend) {
          if (inputElement.value) {
            legend.style.top = "17.5px";
            legend.style.fontSize = "12px";
          } else {
            legend.style.top = "";
            legend.style.fontSize = "";
          }
        }
      }
    };

    const input = inputRef.current;

    updateLegendStyles(input);

    input.addEventListener("focus", function () {
      const inputElement = this as HTMLInputElement;
      const parentElement = inputElement.parentElement;

      if (parentElement) {
        const legend = parentElement.querySelector(
          ".custom-input-legend"
        ) as HTMLElement | null;
        if (legend) {
          legend.style.top = "17.5px";
          legend.style.fontSize = "12px";
        }
      }
    });

    input.addEventListener("blur", function () {
      const inputElement = this as HTMLInputElement;

      const parentElement = inputElement.parentElement;

      if (parentElement && inputElement.value === "") {
        const legend = parentElement.querySelector(
          ".custom-input-legend"
        ) as HTMLElement | null;
        if (legend) {
          legend.style.top = "";
          legend.style.fontSize = "";
        }
      }
    });

    return () => {
      input.removeEventListener("focus", () => {});
      input.removeEventListener("blur", () => {});
    };
  }, [inputRef.current]);

  return (
    <Flex
      flex={flex}
      delay={delay}
      animation={animation}
      {...props}
      style={
        {
          ...style,
          "--xs": xs,
          "--md": md || xs,
          "--lg": lg || md || xs,
        } as CSSProperties
      }
      className={`${className ? className : ""} custom-input-container`}
    >
      <legend className="custom-input-legend">{title}</legend>
      <input
        readOnly={readOnly}
        autoComplete="current-password"
        type={type ?? "text"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && onClick) {
            onClick();
          }
        }}
        className="input"
        ref={inputRef}
        defaultValue={value}
        value={value}
        onChange={onChange}
      ></input>
    </Flex>
  );
};
