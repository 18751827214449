import {
  faCircleNodes,
  faFlaskVial,
  faShieldHalved,
} from "@fortawesome/free-solid-svg-icons";
import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";

export function Idea() {
  const refs = useRef<HTMLDivElement[]>([]);

  const ideas = [
    {
      icon: faFlaskVial,
      name: "Bilimsel Terapi Yöntemi",
      desc: "Online terapi, en güncel psikoterapi teknikleriyle ve bilimsel temellere dayalı olarak hizmet sunan modern bir terapi yöntemidir.",
    },
    {
      icon: faCircleNodes,
      name: "Erişim Kolaylığı",
      desc: "Mekan kısıtlaması olmadan, sadece bir cihaz ve internet bağlantısıyla istediğiniz her yerden terapistinize kolayca ulaşabilirsiniz.",
    },
    {
      icon: faShieldHalved,
      name: "Gizlilik",
      desc: "Online terapi, güvenli bir ortamda gerçekleştirilir ve kişisel bilgileriniz titizlikle korunur.",
    },
  ];

  useEffect(() => {
    function handleScroll() {
      const content = document.getElementById("teraphy");

      if (!content) return;
      if (!refs.current) return;

      const threshold = window.innerHeight / 1.5;
      const top = content.getBoundingClientRect().top || 0;

      refs.current.forEach((element, i) => {
        element.setAttribute(
          "data-anim-hide",
          threshold >= top ? "true" : "false"
        );
      });
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function addRef(e: any) {
    if (e && !refs.current.includes(e)) {
      refs.current.push(e);
    }
  }
  return (
    <section className={style.container} id="teraphy">
      <div className={style.content}>
        <Flex gap="0">
          <h1 className={style.header_title} ref={addRef}>
            Günümüzün hızlı tempolu dünyasında, zaman ve mekân sınırlamaları
            sizi zorlamasın.
          </h1>
        </Flex>
        <div className={style.grid}>
          <Flex>
            {ideas.map((item, i) => (
              <div key={i} className={style.item} ref={addRef}>
                <Flex
                  className={
                    i === ideas.length - 1
                      ? style.items_icon_last
                      : style.items_icon
                  }
                >
                  <FontAwesomeIcon icon={item.icon} />
                </Flex>

                <Flex align="flex-start" style={{ textAlign: "start" }} gap="6">
                  <h3>{item.name}</h3>
                  <label>{item.desc}</label>
                </Flex>
              </div>
            ))}
          </Flex>
          <div ref={addRef} className={style.video_container}>
            <video
              loop
              muted
              autoPlay
              src="/img/video_2.mp4"
              className={style.video}
            ></video>
          </div>
        </div>
      </div>
    </section>
  );
}
