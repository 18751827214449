import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style/style.module.css";
import {
  faBars,
  faCalendarDays,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { Flex } from "../common/flex/flex";
import { ScrollToSection } from "../common/function/scroll-to";

export function Header() {
  const header = useRef<HTMLDivElement | null>(null);

  function setActiveSection(section: string) {
    document.querySelectorAll(`.${style.nav_item}`).forEach((el) => {
      el.setAttribute("data-active", "false");
    });

    const activeSection = document.getElementById(`${section}_text`)!;
    activeSection.setAttribute("data-active", "true");
  }

  useEffect(() => {
    function handleScroll() {
      if (!header.current) return;

      const scrollThreshold = 50;

      const threshold = window.innerHeight / 1.5;
      const homeTop =
        document.getElementById("home")?.getBoundingClientRect().top || 0;
      const aboutTop =
        document.getElementById("about")?.getBoundingClientRect().top || 0;
      const teraphyTop =
        document.getElementById("teraphy")?.getBoundingClientRect().top || 0;
      const serviceTop =
        document.getElementById("service")?.getBoundingClientRect().top || 0;
      if (serviceTop < threshold && serviceTop >= 0)
        setActiveSection("service");
      else if (teraphyTop < threshold && teraphyTop >= 0)
        setActiveSection("teraphy");
      else if (aboutTop < threshold && aboutTop >= 0) setActiveSection("about");
      else if (homeTop < threshold && homeTop >= 0) setActiveSection("home");

      if (window.scrollY > scrollThreshold) {
        header.current.classList.add(style.header_desktop_active);
        return;
      }

      header.current.classList.remove(style.header_desktop_active);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [active, setActive] = useState(false);

  const navigation = [
    { href: "home", title: "Anasayfa" },
    { href: "about", title: "Hakkında" },
    { href: "service", title: "Hizmetler" },
    { href: "teraphy", title: "Online Terapi" },
  ];

  return (
    <>
      <header className={style.header_desktop} ref={header}>
        <Flex direction="row" animation="data-anim-scale" delay={18}>
          <img
            alt=""
            src="img/pnr-logo-transparent.png"
            style={{
              width: "55px",
              objectFit: "contain",
              filter: "brightness(0.25)",
            }}
          />
        </Flex>
        <nav className={style.nav}>
          {navigation.map((item, i) => (
            <Flex
              key={i}
              justify="center"
              delay={18 + i}
              animation="data-anim-translate-right"
              onClick={() => ScrollToSection(item.href)}
            >
              <span className={style.nav_item} id={`${item.href}_text`}>
                {item.title}
              </span>
            </Flex>
          ))}
          <Flex animation="data-anim-scale" delay={22}>
            <button
              onClick={() => ScrollToSection("contact")}
              style={{ borderRadius: "12px", background: "#202b2c" }}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              <span>Iletișim</span>
            </button>
          </Flex>
        </nav>
      </header>
      <header className={style.header_mobile}>
        <Flex direction="row" animation="data-anim-scale" delay={18}>
          <img
            alt=""
            src="img/pnr-logo-transparent.png"
            style={{
              width: "45px",
              objectFit: "contain",
              filter: "brightness(0.25)",
            }}
          />
        </Flex>
        <Flex
          direction="row"
          animation="data-anim-scale"
          delay={20}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon
            size="xl"
            icon={faBars}
            onClick={() => setActive(true)}
          />
        </Flex>
        {active && (
          <Flex className={style.nav_mobile} animation="data-anim-page-up">
            <Flex
              onClick={() => setActive(false)}
              align="flex-end"
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </Flex>
            <Flex gap="64" flex="1" justify="center">
              {navigation.map((item, i) => (
                <Flex
                  key={i}
                  delay={2 + i}
                  animation="data-anim-translate-up"
                  onClick={() => {
                    ScrollToSection(item.href);
                    setActive(false);
                  }}
                >
                  <li style={{ fontSize: "32px" }}>{item.title}</li>
                </Flex>
              ))}
              <Flex
                delay={7}
                style={{ padding: "0 64px" }}
                animation="data-anim-translate-up"
              >
                <button
                  onClick={() => ScrollToSection("contact")}
                  style={{ fontSize: "18px", borderRadius: "12px" }}
                >
                  <FontAwesomeIcon icon={faCalendarDays} />
                  <span>Iletișim</span>
                </button>
              </Flex>
            </Flex>
          </Flex>
        )}
      </header>
    </>
  );
}
