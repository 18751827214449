import style from "./style/style.module.css";
import { Flex } from "../common/flex/flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { ScrollToSection } from "../common/function/scroll-to";
import { useRef } from "react";

export function Welcome() {
  const video = useRef<HTMLVideoElement | null>(null);

  return (
    <section className={style.container} id="home">
      <div className={style.content_bg} />
      <div className={style.text}>
        <Flex gap="32">
          <Flex
            gap="0"
            delay={18}
            style={{ lineHeight: "36px" }}
            animation="data-anim-translate-up"
          >
            <h2 style={{ fontWeight: "700", color: "#EEEEEEAA" }}>
              Uzman Klinik Psikolog
            </h2>
            <h1
              style={{ fontWeight: "700", color: "#EEE", lineHeight: "46px" }}
            >
              Pınar Şenadım
            </h1>
          </Flex>
          <Flex delay={21} animation="data-anim-translate-up">
            <button
              style={{ width: "300px" }}
              onClick={() => ScrollToSection("contact")}
              className={style.welcome_button}
            >
              <FontAwesomeIcon icon={faMessage} />
              <span>Iletişime Geç</span>
            </button>
          </Flex>
        </Flex>
      </div>
      <Flex className={style.video_container}>
        <video
          ref={video}
          loop
          muted
          autoPlay
          playsInline
          src="/img/main3.mp4"
          className={style.video}
        />
      </Flex>
    </section>
  );
}
