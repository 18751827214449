import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import style from "./style/style.module.css";
import { Flex } from "../common/flex/flex";
import emailjs from "emailjs-com";
import { useEffect, useRef, useState } from "react";
import { config } from "../../config";
import { Input } from "../common/input/input";
import { UseQueryIp } from "./hooks/use-query-ip";

export function Contact() {
  const refs = useRef<HTMLDivElement[]>([]);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [ip, setIp] = useState("");
  const [video, setVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const [email, setEmail] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (!localStorage.getItem("video")) localStorage.setItem("video", "1");

    UseQueryIp().then((data) => {
      setIp(data.ip);
    });

    function handleScroll() {
      const content = document.getElementById("contact");
      if (!content) return;
      if (!refs.current) return;

      const threshold = window.innerHeight / 1.5;
      refs.current.forEach((element, i) => {
        const top = element.getBoundingClientRect().top || 0;
        element.setAttribute(
          "data-anim-hide",
          threshold >= top ? "true" : "false"
        );
        element.style.setProperty("--animation-delay", `${i}`);
      });
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const badWordsEnglish = [
    "fuck",
    "idiot",
    "stupid",
    "moron",
    "bitch",
    "asshole",
    "bastard",
    "crap",
    "dick",
    "shit",
    "slut",
    "whore",
    "damn",
    "faggot",
    "piss",
    "cunt",
    "wanker",
    "prick",
    "twat",
  ];
  const badWordsTurkish = [
    "aptal",
    "salak",
    "ahmak",
    "orosbu",
    "orospu",
    "gerizekalı",
    "kaltak",
    "mal",
    "lanet",
    "yavşak",
    "puşt",
    "şerefsiz",
    "aptalca",
    "kahpe",
    "sürtük",
    "ananı",
    "pezevenk",
    "piç",
  ];

  function containsBadWords(text: string, badWordsList: string[]) {
    const lowerText = text.toLowerCase();
    return badWordsList.some((word) => lowerText.includes(word));
  }

  function checkEmailForBadWords(emailBody: string) {
    const isEnglishOffensive = containsBadWords(emailBody, badWordsEnglish);
    const isTurkishOffensive = containsBadWords(emailBody, badWordsTurkish);

    if (isEnglishOffensive || isTurkishOffensive) {
      return true;
    }
    return false;
  }

  function phoneRegex(number: string) {
    if (number === undefined) return "";

    const phone = number.replace(/[^0-1-2-3-4-5-6-7-8-9/+]/g, "");
    return phone;
  }

  function closeVideo() {
    videoRef.current!.pause();
    if (localStorage.getItem("video") === "1")
      localStorage.setItem("video", "2");
    else localStorage.setItem("video", "1");
    setVideo(false);
  }

  async function sendEmail() {
    setVideo(true);
    await videoRef.current!.play();

    setMessage(null);

    if (ip === "78.135.2.31") {
      return;
    }

    if (ip === "185.43.231.195") {
      return;
    }

    if (ip === "185.43.231.74") {
      return;
    }

    if (email.name === "" || email.name.length < 3) {
      setMessage("Lutfen isim alanini doldurunuz");
      return;
    }

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.email)) {
      setMessage("Lutfen gecerli email adresi giriniz");
      return;
    }

    if (email.phone.length < 7 && email.phone.length > 13) {
      setMessage("Lutfen gecerli telefon numarasi giriniz");
      return;
    }

    if (checkEmailForBadWords(email.message)) {
      setMessage(
        "Cok ayip, lutfen kelimelerinizi secip mesaj gonderiniz. Ip Addresiniz kayit olmustur, bir sonraki sefer beyninizi kullanarak mesaj iletiniz."
      );
      return;
    }

    setMessage(null);

    try {
      emailjs.init(config.email_key);
      setIsLoading(true);
      await emailjs.send(config.email_service, config.email_template, {
        from_email: email.email,
        from_ip: ip,
        from_name: email.name,
        message: email.message,
        from_phone: email.phone,
        from_ip_link: `https://www.ip2location.com/demo/${ip}`,
      });
      setMessage("Email gonderildi.");
      setEmail({ ...email, name: "", message: "", email: "", phone: "" });
    } catch (error) {
      console.error("Hata olustu.", error);
    }

    setIsLoading(false);
  }

  function addRef(e: any) {
    if (e && !refs.current.includes(e)) {
      refs.current.push(e);
    }
  }

  return (
    <div className={style.container} id="contact">
      {/* <img alt="" src="/img/contact-bg.png" className={style.content_bg} /> */}
      <div className={style.content}>
        <Flex
          gap="0"
          align="flex-start"
          style={{
            fontSize: "26px",
            lineHeight: "32px",
          }}
        >
          <h2 style={{ color: "#FFF" }}>Hazır mısınız ? </h2>
          <p style={{ textAlign: "start", opacity: "0.75", color: "#FFF" }}>
            Terapistinizle ücretsiz 15 dakikalık ön görüşmenizi hemen planlayın.
          </p>
        </Flex>
        <div className={style.message} ref={addRef}>
          <div className={style.rez}>
            <Flex direction="row">
              <Input
                flex="1"
                title="Ad Soad"
                value={email.name}
                onChange={(e) => setEmail({ ...email, name: e.target.value })}
              />
              <Input
                flex="1"
                title="Telefon"
                value={email.phone}
                onChange={(e) =>
                  setEmail({ ...email, phone: phoneRegex(e.target.value) })
                }
              />
            </Flex>

            <Input
              flex="1"
              title="Email"
              value={email.email}
              onChange={(e) => setEmail({ ...email, email: e.target.value })}
            />
            <Input
              flex="1"
              title="Mesaj"
              value={email.message}
              onChange={(e) => setEmail({ ...email, message: e.target.value })}
            />
            {message && <span className={style.error}>{message}</span>}

            <div>
              <br />
              <button
                className={style.send_button}
                onClick={sendEmail}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
                <span>GONDER</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={style.video} data-active={video}>
        <div className={style.close} onClick={closeVideo} />
        <video
          loop
          playsInline
          ref={videoRef}
          src={`/img/${
            localStorage.getItem("video") === "1" ? "ibo" : "seda"
          }.mp4`}
        />
      </div>
    </div>
  );
}
