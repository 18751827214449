import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";

export function About() {
  return (
    <section className={style.container} id="about">
      <div className={style.content}>
        <div className={style.text_content}>
          <Flex gap="0" padding="0 0 32px 0" style={{ lineHeight: "38px" }}>
            <strong
              style={{
                fontSize: "22px",
                fontWeight: "400",
                color: "#FFFFFFAA",
              }}
            >
              Uzman Klinik Psikolog
            </strong>
            <strong style={{ fontSize: "48px", color: "#FFF" }}>
              Pınar Şenadım
            </strong>
          </Flex>
          <p className={style.text}>
            Lisans eğitimini Çağ Üniversitesi Psikoloji Bölümü’nde, İngilizce
            olarak tamamladı. Eğitim sürecinde, çeşitli hastanelerin psikiyatri
            bölümlerinde gönüllü olarak hasta gözlemlerinde bulundu ve klinik
            deneyim kazandı. Yüksek lisans eğitimini ise Çukurova
            Üniversitesi'nde "Zorunlu Göç Eden Bireylerin Bağımlılık Profili ile
            Travma Sonrası Stres Bozukluğunun İlişkisi" konulu tez çalışmasıyla
            tamamladı.
          </p>
          <br />
          <p className={style.text}>
            Profesyonel kariyerine, Birleşmiş Milletler Uluslararası Göç Örgütü
            (IOM) bünyesinde psikolog olarak başladı. Daha sonra, bağımlılık
            alanında hizmet veren Yeşilay Danışmanlık Merkezleri'nde Uzman
            Psikolog olarak çalıştı. Ulusal ve uluslararası düzeyde birçok
            kongrede eğitimler verdi.{" "}
          </p>
          <br />
          <p className={style.text}>
            Pınar Şenadım, Travma Sonrası Stres Bozukluğu, Anksiyete, Depresyon,
            Öfke Kontrol Bozuklukları, Yeme Bozuklukları, Obsesif Kompulsif
            Bozukluk ve Bağımlılık gibi alanlarda uzmandır. Klinik
            çalışmalarında birey odaklı yaklaşımı benimseyerek; Bilişsel
            Davranışçı Terapi, Kabul ve Kararlılık Terapisi ve Mindfulness gibi
            modern terapi yöntemleriyle çalışmaktadır.
          </p>
        </div>
        <div className={style.img_container}>
          {/* <img src="/img/about.png" alt="" className={style.img} /> */}
        </div>
      </div>
    </section>
  );
}
