import { Flex } from "../../common/flex/flex";
import style from "../style/style.module.css";

interface Props {
  title1: string;
  title2: string;
  desc: string;
}
export function ServiceItem({ title1, title2, desc }: Props) {
  return (
    <div className={style.card}>
      <Flex
        gap="0"
        align="flex-start"
        padding="22px 24px 22px 24px"
        style={{ background: "rgba(0,0,0,0.25)" }}
      >
        <h5 style={{ margin: "0" }}>{`${title1} ${title2}`}</h5>
      </Flex>
      <Flex
        flex="1"
        justify="center"
        padding="8px 24px 24px 24px"
        style={{ background: "rgba(136,136,136,.05)" }}
      >
        <Flex direction="row" align="center" justify="center">
          <p className={style.p}>{desc}</p>
        </Flex>
      </Flex>
    </div>
  );
}
