import { Flex } from "../common/flex/flex";

export function CopyRight() {
  return (
    <Flex
      align="center"
      direction="row"
      padding="24px 32px"
      justify="space-between"
      style={{ flexWrap: "wrap", zIndex: 2, background: "#FFF" }}
    >
      <Flex>
        <label>© Copyright All Rights Reserved By Pinar Senadim 2024</label>
      </Flex>
      <Flex direction="row" gap="12">
        <label>Design by</label>
        <img alt="" width={40} height={40} src="/img/island-software.png" />
        <strong>Island Software</strong>
      </Flex>
    </Flex>
  );
}
