import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { FallbackLoader } from "../fallback-loader";
import { Flex } from "../../common/flex/flex";
import style from "./style/style.module.css";

export function Book() {
  useGLTF.preload("./models/psychology_v3.glb");

  const { scene } = useGLTF("./models/psychology_v3.glb");

  return <primitive object={scene.clone()} />;
}

export function ModelBook() {
  return (
    <section className={style.container}>
      <div className={style.canvas}>
        <Canvas
          shadows
          gl={{ preserveDrawingBuffer: true }}
          camera={{
            fov: 50,
            zoom: 1,
            far: 2000,
            near: 0.1,
          }}
        >
          <Suspense fallback={<FallbackLoader />}>
            <mesh position={[0, -0.5, 0]} rotation={[0.1, 0, 0]} scale={0.02}>
              <Book />
            </mesh>
            {/* <ambientLight intensity={1} /> */}
            <Environment preset="sunset" />
            <OrbitControls
              autoRotate
              enablePan={true}
              enableZoom={false}
              autoRotateSpeed={2.75}
            />
          </Suspense>
        </Canvas>
      </div>
      <Flex className={style.text}>
        <h2
          style={{
            color: "#FFF",
            fontWeight: "400",
            textAlign: "start",
            fontStyle: "italic",
          }}
        >
          Kendini tanmayan insan, hayatin baskalarmin yönlendirmelerine brakir.
          <p
            style={{
              fontSize: "14px",
              color: "#FFFFFFAA",
              fontStyle: "italic",
            }}
          >
            Carl Jung
          </p>
        </h2>
      </Flex>
    </section>
  );
}
