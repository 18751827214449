import "./App.css";
import { Footer } from "./module/footer/footer";
import { Loading } from "./module/loading/loading";
import { Header } from "./module/header/header";
import { CopyRight } from "./module/copy-right/copy-right";
import { Service } from "./module/service/service";
import { About } from "./module/about/about";
import { Welcome } from "./module/welcome/welcome";
import { Idea } from "./module/idea/idea";
import { ModelBook } from "./module/models/book/book";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Welcome />
        <About />
        <ModelBook />
        <Service />
        <Idea />
      </main>
      <Footer />
      <CopyRight />
      <Loading />
    </div>
  );
}

export default App;
