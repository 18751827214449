import { CSSProperties, LegacyRef, PropsWithChildren } from "react";
import CssStyle from "./style/style.module.css";

export type FlexType = PropsWithChildren<{
  ref?: LegacyRef<HTMLDivElement>;
  gap?: string;
  flex?: string;
  delay?: number;
  padding?: string;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
  align?: "center" | "flex-end" | "flex-start";
  direction?: "column" | "row";
  justify?: "space-between" | "center" | "flex-end" | "flex-start";
  animation?:
    | "data-anim-scale"
    | "data-anim-page-up"
    | "data-anim-translate-up"
    | "data-anim-translate-left"
    | "data-anim-translate-right";
}>;

export const Flex: React.FC<FlexType> = ({
  ref,
  style,
  delay,
  onClick,
  className,
  children,
  gap = "16",
  padding = "0",
  align = "unset",
  justify = "unset",
  direction = "column",
  flex = "auto auto auto",
  animation = "",
  ...props
}) => {
  return (
    <div
      onClick={() => {
        !!onClick && onClick();
      }}
      {...{ [animation ? animation : "data-empty"]: true }}
      className={`${CssStyle.flex} ${!!className ? className : ""}`}
      style={
        {
          ...style,
          "--flex": flex,
          "--align": align,
          "--gap": `${gap}px`,
          "--justify": justify,
          "--padding": padding,
          "--direction": direction,
          "--animation-delay": delay,
        } as CSSProperties
      }
      {...props}
    >
      {children}
    </div>
  );
};
