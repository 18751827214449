import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flex } from "../../common/flex/flex";
import style from "../style/style.module.css";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export function ServiceDrugTeraphy() {
  return (
    <div className={style.card}>
      <Flex
        gap="0"
        align="flex-start"
        padding="24px 24px 12px 24px"
        style={{ background: "rgba(0,0,0,0.25)" }}
      >
        <h5 style={{ margin: "0" }}>Bağımlılık Danışmanlığı</h5>
      </Flex>
      <Flex
        gap="6"
        flex="1"
        justify="center"
        align="flex-start"
        className={style.detail}
        padding="8px 24px 24px 24px"
      >
        <Flex direction="row" align="center" gap="10">
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={9}
            style={{ opacity: 0.5 }}
          />
          <p className={style.p}>Alkol/Madde Bağımlılığı</p>
        </Flex>
        <Flex direction="row" align="center" gap="10">
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={9}
            style={{ opacity: 0.5 }}
          />
          <p className={style.p}>Kumar Bağımlılığı</p>
        </Flex>
        <Flex direction="row" align="center" gap="10">
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={9}
            style={{ opacity: 0.5 }}
          />
          <p className={style.p}>İnternet Bağımlılığı</p>
        </Flex>
        <Flex direction="row" align="center" gap="10">
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={9}
            style={{ opacity: 0.5 }}
          />
          <p className={style.p}>Sigara Bağımlılığı</p>
        </Flex>
      </Flex>
    </div>
  );
}
