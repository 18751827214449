import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";
import { ScrollToSection } from "../common/function/scroll-to";
import { Contact } from "../contact/contact";

export function Footer() {
  return (
    <footer className={style.footer}>
      <Contact />
      <Flex className={style.menu}>
        <Flex className={style.navigation}>
          <span onClick={() => ScrollToSection("home")}>Anasayfa</span>
          <span onClick={() => ScrollToSection("about")}>Hakkında</span>
          <span onClick={() => ScrollToSection("service")}>Hizmetler</span>
          <span onClick={() => ScrollToSection("teraphy")}>Online Terapi</span>
          <span onClick={() => ScrollToSection("contact")}>Iletișim</span>
        </Flex>
        <Flex direction="row">
          <img src="/img/icons8-facebook-48.png" alt="" width={28} />
          <img src="/img/icons8-instagram-48.png" alt="" width={28} />
        </Flex>
      </Flex>
    </footer>
  );
}
